import { ClickStream } from "./clickstream";
var InfinityIFrame = (function () {
    function InfinityIFrame(iframe, window) {
        this.iframe = iframe;
        this.window = window;
    }
    InfinityIFrame.prototype.triggerInfinity = function (iframeTargetOrigin, data, url, custServNum) {
        data["pageTitle"] = document.title;
        data["href"] = this.window.location.href;
        data["referrer"] = document.referrer;
        data["host"] = this.window.location.host;
        if (custServNum !== undefined) {
            data["csNumber"] = custServNum;
            var pageProperties = { custServNum: custServNum };
            ClickStream.postClickStreamWithTrackingArguments({
                pageAction: "gtm_iframe_cust_serv_num",
                servletName: "gtmIframe",
                pageProperties: pageProperties
            });
        }
        this.iframe.contentWindow.postMessage(data, iframeTargetOrigin);
        if (this.window.addEventListener) {
            this.window.addEventListener("message", displayMessage, false);
        }
        function displayMessage(evt) {
            if (!(endsWith(evt.origin, ".viator.com") || endsWith(evt.origin, ".viator.com:8432"))) {
                return;
            }
            if (evt.data instanceof Object && "number" in evt.data) {
                var allocatedNumber = evt.data.number;
                $(".InfinityNumber").text(allocatedNumber);
                var newHref = "tel:" + allocatedNumber;
                $("a.InfinityNumber").attr("href", newHref);
                var pageProperties = { allocatedNumber: allocatedNumber };
                ClickStream.postClickStreamWithTrackingArguments({
                    pageAction: "gtm_iframe_infinity_num_loaded",
                    servletName: "gtmIframe",
                    pageProperties: pageProperties
                });
            }
        }
        function endsWith(evtOrigin, suffix) {
            return evtOrigin.indexOf(suffix, evtOrigin.length - suffix.length) !== -1;
        }
    };
    return InfinityIFrame;
}());
export { InfinityIFrame };
