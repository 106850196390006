import { InfinityIFrame } from "./infinityIframe";
var InfinityLoader = (function () {
    function InfinityLoader(window) {
        var iframediv = $("#infinityDiv")[0];
        if (!iframediv) {
            return;
        }
        var iframe = document.createElement("iframe");
        iframe.setAttribute("id", $("viator-infinity").data("id"));
        iframe.setAttribute("frameborder", "0");
        iframe.setAttribute("scrolling", "no");
        iframe.setAttribute("sandbox", "allow-scripts allow-forms allow-same-origin");
        iframe.setAttribute("style", "border:none;position:relative;display:block;margin:0;padding:0;width:100%;height:0");
        iframe.src = $("viator-infinity").data("src");
        var custNumberSpan = $("span.InfinityNumber")[0];
        if (custNumberSpan !== undefined) {
            var custServNum_1 = custNumberSpan.innerHTML;
            if (this.isValidPhonePattern(custServNum_1)) {
                var iframeTargetOrigin_1 = $("viator-infinity").data("origin");
                iframe.onload = function () {
                    new InfinityIFrame(iframe, window).triggerInfinity(iframeTargetOrigin_1, $("viator-infinity").data("value"), $("viator-infinity").data("url"), custServNum_1);
                };
                iframediv.appendChild(iframe);
            }
        }
    }
    InfinityLoader.prototype.isValidPhonePattern = function (custServNum) {
        var phonePattern = new RegExp(/^[+]*[0-9]*.[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g);
        if (phonePattern.test(custServNum)) {
            return true;
        }
        return false;
    };
    return InfinityLoader;
}());
export { InfinityLoader };
