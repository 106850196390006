import { fromEvent as observableFromEvent } from "rxjs";
import { debounceTime, filter, map } from "rxjs/operators";
var DEFAULT_INPUT_DEBOUNCE_TIME = 300;
var CardContainer = (function () {
    function CardContainer($cardContainer) {
        this.watchCardClickEvent($cardContainer);
        this.watchCardDOMChangeEvent($cardContainer);
        this.handleCardContainerModal($cardContainer);
    }
    CardContainer.prototype.watchCardClickEvent = function ($productListContainer) {
        observableFromEvent($productListContainer, "click")
            .pipe(map(function (event) { return $(event.target); }), filter(function ($target) { return !$target.is("a"); }), filter(function ($target) {
            return !$target.closest(".no-trigger-card-click").length;
        }), filter(function ($target) {
            if ($target.is(".debug-info")) {
                $target.siblings(".debug-info-details").toggle();
                return false;
            }
            return true;
        }), map(function ($target) { return $target.parents(".orion-card"); }), filter(function ($card) { return $card.data("url"); }), map(function ($card) { return [
            $card.data("url"),
            $card.data("same-window"),
        ]; }))
            .subscribe(function (_a) {
            var url = _a[0], openInSameWindow = _a[1];
            window.open(url, openInSameWindow ? "_self" : "_blank");
        });
    };
    CardContainer.prototype.watchCardDOMChangeEvent = function ($productListContainer) {
        var _this = this;
        observableFromEvent($productListContainer, "DOMSubtreeModified")
            .pipe(debounceTime(DEFAULT_INPUT_DEBOUNCE_TIME))
            .subscribe(function () { return _this.handleCardContainerModal($productListContainer); });
    };
    CardContainer.prototype.handleCardContainerModal = function ($productListContainer) {
        var modal = $(".mobile-product-overview-modal");
        if (modal) {
            $productListContainer
                .find(".mobile-product-overview,.mobile-product-description-modal")
                .each(function (index, element) {
                $(element).on("click", function (event) {
                    event.stopPropagation();
                    var modalHeader = modal.find(".mobile-product-overview-modal-header");
                    var productHref = $(element)
                        .closest(".product-card-inner-row")
                        .find(".product-card-row-title")
                        .find("a");
                    modalHeader.html(productHref.html());
                    modal
                        .find(".mobile-product-overview-modal-link")
                        .attr("href", productHref.attr("href"));
                    var modalText = modal.find(".mobile-product-overview-modal-text");
                    modalText.html($(element)
                        .closest(".product-card-inner-row")
                        .find(".prod-desc-container")
                        .find(".text-body")
                        .html());
                    modal.modal("show");
                });
            });
        }
    };
    return CardContainer;
}());
export { CardContainer };
