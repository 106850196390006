import "../scss/_pagination.scss";
import "../scss/_list-group.scss";
import "../scss/_shop-by-category.scss";
import "../scss/filters.scss";
import "../scss/_user_actions.scss";
import "../ts/shelf";
import "../scss/_product-card.scss";
import "../scss/_super-filter.scss";
import "../scss/_dynamic-filter.scss";
import "../scss/listing.scss";
import "../scss/reserveNowPayLater.scss";
import "../scss/listing/sort.scss";
import "../scss/listing/bookWithConfidence.scss";
import "../scss/cms/common/reviewsDisclaimer.scss";
import $ from "../ts/common/jquery";
$("#displayed-sort").on('DOMSubtreeModified', function () {
    var currentVal = $("#displayed-sort").text();
    if (currentVal === 'Featured') {
        $("#orion-sort-order-all-other-label").addClass('d-none').removeClass('d-inline-block');
        $("#orion-sort-order-featured-label").addClass('d-inline-block').removeClass('d-none');
    }
    else {
        $("#orion-sort-order-all-other-label").addClass('d-inline-block').removeClass('d-none');
        $("#orion-sort-order-featured-label").addClass('d-none').removeClass('d-inline-block');
    }
});
