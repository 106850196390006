import $ from "./common/jquery";
var GoogleTagManager = (function () {
    function GoogleTagManager(containerID, window, $document) {
        this.$window = window;
        (function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
            var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src =
                '//www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
        })(this.$window, $document, 'script', 'dataLayer', containerID);
        this.$window.dataLayer.push($("viator-gtm").data("value"));
    }
    GoogleTagManager.prototype.pushDataToGtm = function (data) {
        this.$window.dataLayer.push(data);
    };
    return GoogleTagManager;
}());
export { GoogleTagManager };
