import { GTMIFrame } from "./gtmIframe";
import { GoogleTagManager } from "./gtm";
import $ from "./common/jquery";
var GTMLoader = (function () {
    function GTMLoader(containerID, windowObj, onLoadCallBack, $viatorGTMSelector) {
        if (windowObj === void 0) { windowObj = window; }
        this.containerId = containerID;
        this.window = windowObj;
        this.$viatorGTMSelector = $viatorGTMSelector || $("viator-gtm");
        this.onLoadCallback = onLoadCallBack;
        this.init();
    }
    GTMLoader.prototype.getGtmIframe = function () {
        return this.gtmIframe;
    };
    GTMLoader.prototype.init = function () {
        var _this = this;
        var $gtmTagAndData = this.$viatorGTMSelector;
        if ($gtmTagAndData.length) {
            var iframeEnabled = $gtmTagAndData.data("enabled");
            if (iframeEnabled === true) {
                var iframe_1 = document.createElement("iframe");
                iframe_1.setAttribute("id", $gtmTagAndData.data("id"));
                iframe_1.setAttribute("frameborder", "0");
                iframe_1.setAttribute("scrolling", "no");
                iframe_1.setAttribute("sandbox", "allow-scripts allow-forms allow-same-origin");
                iframe_1.setAttribute("style", "border:none;position:relative;display:block;margin:0;padding:0;width:100%;height:0");
                iframe_1.onload = function () {
                    _this.gtmIframe = new GTMIFrame(iframe_1, _this.window, $gtmTagAndData.data("origin"));
                    _this.gtmIframe.triggerGTMTags($gtmTagAndData.data("value"), _this.containerId);
                    $(document).trigger("gtmIFrameLoad", _this.gtmIframe);
                    if (typeof _this.onLoadCallback === "function") {
                        _this.onLoadCallback(_this.gtmIframe);
                    }
                };
                var iframeSrc = $gtmTagAndData.data("src");
                var semParameters = $gtmTagAndData.data("value").semParameters;
                var semParameterValues = Object.entries(semParameters !== undefined ? semParameters : {})
                    .filter(function (_a) {
                    var value = _a[1];
                    return !!value;
                })
                    .map(function (_a) {
                    var key = _a[0], value = _a[1];
                    return encodeURIComponent(key) + "=" + encodeURIComponent(String(value));
                })
                    .join('&');
                if (semParameterValues) {
                    iframeSrc = iframeSrc + "?" + semParameterValues;
                }
                iframe_1.setAttribute('src', iframeSrc);
                $gtmTagAndData.append(iframe_1);
            }
            else {
                new GoogleTagManager(this.containerId, this.window, this.window.document);
            }
        }
    };
    return GTMLoader;
}());
export { GTMLoader };
