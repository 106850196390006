var GTMIFrame = (function () {
    function GTMIFrame(iframe, window, iframeTargetOrigin) {
        this.iframe = iframe;
        this.window = window;
        this.iframeTargetOrigin = iframeTargetOrigin;
        this.listenForIFrameErrors();
    }
    GTMIFrame.prototype.triggerGTMTags = function (data, containerId) {
        data["pageTitle"] = document.title;
        data["href"] = this.window.location.href;
        data["referrer"] = document.referrer;
        data["host"] = this.window.location.host;
        data["containerID"] = containerId;
        this.iframe.contentWindow.postMessage(data, this.iframeTargetOrigin);
    };
    GTMIFrame.prototype.pushDataToGtm = function (data) {
        this.iframe.contentWindow.postMessage(data, "*");
    };
    GTMIFrame.prototype.listenForIFrameErrors = function () {
        var _this = this;
        this.window.addEventListener &&
            this.window.addEventListener("message", function (messageEvent) {
                if (messageEvent.origin === _this.iframeTargetOrigin &&
                    messageEvent.source === _this.iframe.contentWindow &&
                    messageEvent.data.javascriptErrorPostMessageEvent) {
                    throw new Error("GTM ERROR: " + messageEvent.data.javascriptErrorPostMessageEvent.message);
                }
            });
    };
    return GTMIFrame;
}());
export { GTMIFrame };
