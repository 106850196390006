import "../../scss/backToTopButton.scss";
import { fromEvent as observableFromEvent } from "rxjs";
var BackToTopButton = (function () {
    function BackToTopButton(window, $backToTopButton) {
        var _this = this;
        observableFromEvent(window, "scroll")
            .subscribe(function () { return _this.toggleBackToTopButton($backToTopButton, window); });
        observableFromEvent($backToTopButton, "click")
            .subscribe(function () { return _this.backToTop($backToTopButton, window); });
    }
    BackToTopButton.prototype.backToTop = function ($backToTopButton, window) {
        window.scrollTo(0, 0);
        $backToTopButton.hide();
    };
    BackToTopButton.prototype.toggleBackToTopButton = function ($backToTopButton, window) {
        if (window.pageYOffset > window.innerHeight) {
            $backToTopButton.show();
        }
        else {
            $backToTopButton.hide();
        }
    };
    return BackToTopButton;
}());
export { BackToTopButton };
